.router-wrapper {
  position: relative;

  overflow: hidden;

  min-height: 100vh;
}

.forward-enter {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(100%);
}

.forward-enter-active {
  transition: all 500ms;
  transform: translateX(0);
}

.forward-exit {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(0);
}

.forward-exit-active {
  transition: all 500ms;
  transform: translateX(-100%);
}

.back-enter {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(-100%);
}

.back-enter-active {
  transition: all 500ms;
  transform: translateX(0);
}

.back-exit {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(0);
}

.back-exit-active {
  transition: all 500ms;
  transform: translateX(100%);
}

@bgColor: #f4f5f7;