html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
html {
	font-size: 20px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root:root {
  --adm-color-primary: #0039BF;
}
.detail-wrapper {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100vh;
  height: 100vh;
}

.detail-forward-enter {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(100%);
}

.detail-forward-enter-active {
  transition: all 500ms;
  transform: translateX(0);
}

.detail-forward-exit {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(0);
}

.detail-forward-exit-active {
  transition: all 500ms;
  transform: translateX(-100%);
}

.detail-back-enter {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(-100%);
}

.detail-back-enter-active {
  transition: all 500ms;
  transform: translateX(0);
}

.detail-back-exit {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100vh;

  transform: translateX(0);
}

.detail-back-exit-active {
  transition: all 500ms;
  transform: translateX(100%);
}

@bgColor: #f4f5f7;