.container {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: #f4f5f7;
  padding-left: 3rem;
}
.btn {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 100%;
}
.btn-inner {
  transform: translateX(-50vh) rotateZ(90deg) translateY(-1.5rem) translateX(50vh) translateX(-1.5rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vh;
  height: 3rem;
  box-sizing: border-box;
  padding:0 1rem;
  border-top: 1px solid #efefef;
}
.canvas {
  position: relative;
  flex: 1;
  height: 100%;
  background-color: #fff;
}
.modal {
  padding: 1rem;
  position: relative;
  z-index: 1000;
}
.modal-description {
  font-size: .7rem;
  color: #666;
  line-height: 2rem;
}
.modal-input-line {
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.modal-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #efefef;
  border-radius: .4rem;
  padding: .5rem;
  font-size: .7rem;
  color: #333;
}
.modal-sms-btn {
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: .4rem;
  font-size: .7rem;
  background-color: #fff;
  flex-shrink: 0;
  box-sizing: border-box;
  &.disabled {
    background-color: #efefef;
    color: #999;
  }
}
.modal-input-left {
  flex: 1;
  height: 2rem;
  padding-right: 1rem;
}
.modal-input-right {
  width: 4.5rem;
  height: 2rem;
}
.modal-btns {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@bgColor: #f4f5f7;