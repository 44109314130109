.container {
  box-sizing: border-box;
  min-height: 100vh;
  padding: 1rem;

  background-color: @bgColor;
  .icon {
    width: 60px !important;
    height: 60px !important;
  
    fill: '#F13642' !important;
  }
}


@bgColor: #f4f5f7;