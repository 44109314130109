@import url('/src/total.less');
.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.header {
  flex-shrink: 0;
}
.title {
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  padding: .5rem 10px 0 10px;
}
.video {
  padding: 10px;
}
.player {
  background-color: #000;
  min-width: 100%;
}
.content {
  position: relative;
  flex: 1;
}
.content-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.comment-title {
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 0.8rem;
}
.comment-title-left {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
}
.first {
  margin-right: .2rem;
}
.footer {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  background-color: #fff;
}
.comment-input-container {
  padding: .4rem;
  display: flex;
  flex-direction: row;
}
.comment-input {
  height: 2rem;
  line-height: 1.5rem;
  padding: .25rem;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: .5rem;
  flex: 1;
}
.comment-btn {
  height: 2rem;
  width: 3rem;
  background-color: @primary-color;
  color: #fff;
  border-radius: .3rem;;
  margin-left: .5rem;
  flex-shrink: 0;
}
.comment-item {
  margin-bottom: .5rem;
}
.comment-item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.2rem;
  padding: 0 10px;
}
.comment-item-info-left {
  font-size: .8rem;
  color: #999;
}
.comment-item-info-name {
  margin-right: .3rem;
  &.active {
    color: @primary-color;
  }
}
.comment-item-time {
  font-size: 0.7rem;
}
.comment-del {
  padding: 0.2rem 0 0.2rem 0.3rem;
  color: #999;
}
.comment-content {
  padding: .2rem 10px;
  font-size: .8rem;
  color: #555;
  line-height: 1.2rem;
}
.noread {
  display: flex;
  height: 40vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
}
.noread-icon {
  color: red;
  font-size: 3rem;
  margin-bottom: .5rem;
}
@bgColor: #f4f5f7;