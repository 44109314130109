.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.pdf {
  flex: 1;
  overflow: auto;
}
.page + .page {
  border-top: 1px solid #efefef;
}
.loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loading-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn {
  flex-shrink: 0;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #efefef;
}
.reject {
  margin-top: .5rem;
}
.loading-text {
  font-size: 1rem;
  line-height:3rem;
}
.modal-content {
  padding: .5rem;
  border: 1px solid #ddd;
  &.error {
    border-color: red;
  }
}
.notice {
  font-size: .6rem;
  color: red;
  line-height: .8rem;
}
@bgColor: #f4f5f7;